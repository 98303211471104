import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
// import Seo from "../components/seo"

const AllServiceAreasPage = (props) => {
    let cityList = [
        { city: "Auburn", slug: "Auburn" }, { city: "Sacramento", slug: "Sacramento" }]
    return (
        <div>
            <Layout>
                <Seo
                    title="Areas Served by Kaylar Construction: Kitchen Remodel, Bathroom Remodel, Siding and Painting Services."
                    description="Explore our extensive service areas for top-quality general contracting in kitchen remodeling, bathroom remodeling, siding, window replacements, fire & water restorations, flooring, new construction and painting. Trust our expertise in delivering exceptional results wherever you are."
                    location={props.location.pathname}
                    keywords={[
                        'Kitchen remodel',
                        'Bathroom remodel',
                        'Fire Restoration',
                        'Water Restoration',
                        'Flooring',
                        'New Construction',
                        'Painting',
                        'Siding',
                        'Window Replacement'
                    ]}
                />
                <div>
                    <div className="px-5 text-center pt-40 xl:pt-44">
                        <h1 className="font-bold text-3xl md:text-4xl">
                            Service Areas
                        </h1>
                        <p className="max-w-4xl mx-auto text-sm lg:pt-6 md:text-base px-3">
                            Get the home renovation of your dreams with our experienced general contracting services! We specialize in siding, windows, kitchen and bathroom remodeling, deck and patio construction, and interior design services.
                            Let us turn your vision into reality and transform your living space into a beautiful, functional, and comfortable environment. <Link to="/contact" className="text-darkblue hover:text-lightblue text-sm md:text-base" title="Contact Kaylar Construction" alt="Contact Kaylar Construction">Contact us</Link> today for a free consultation and estimate.
                        </p>
                    </div>

                    <div className="container mx-auto p-2 rounded-2xl">
                        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mx-auto">
                            {cityList.map(function (element) {
                                return (
                                    <div className="px-4 sm:px-1 md:ml-24 lg:ml-14 xl:ml-10">
                                        <span id={element.city} className="text-sm font-bold uppercase pb-3 text-darkblue">{element.city}, CA</span>
                                        {/* <ul className="font-light text-sm">
                                        <li className="pb-1 hover:text-lightblue">
                                                <Link to={'/' + element.slug.toLowerCase() + '-kitchen-remodel'} title={element.city + ' Kitchen Remodel'} alt={element.city + ' Kitchen Remodel'}><span>{element.city} Kitchen Remodel</span></Link>
                                            </li>
                                            <li className="pb-1 hover:text-lightblue">
                                                <Link to={'/' + element.slug.toLowerCase() + '-bathroom-remodel'} title={element.city + ' Bathroom Remodel'} alt={element.city + ' Bathroom Remodel'}><span>{element.city} Bathroom Remodel</span></Link>
                                            </li>
                                            <li className="pb-1 hover:text-lightblue">
                                                <Link to={'/' + element.slug.toLowerCase() + '-fire-water-restoration'} title={element.city + ' Fire & Water Restoration'} alt={element.city + ' Fire & Water Restoration'}><span>{element.city} Fire & Water Restoration</span></Link>
                                            </li>
                                            <li className="pb-1 hover:text-lightblue">
                                                <Link to={'/' + element.slug.toLowerCase() + '-siding'} title={element.city + ' Siding'} alt={element.city + ' Siding'}><span>{element.city} Siding</span></Link>
                                            </li>
                                            <li className="pb-7 hover:text-lightblue">
                                                <Link to={'/' + element.slug.toLowerCase() + '-window-replacement'} title={element.city + ' Window Replacement'} alt={element.city + ' Window Replacement'}><span>{element.city} Window Replacement</span></Link>
                                            </li>
                                        </ul> */}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default AllServiceAreasPage